import { FullWidthContainer } from "../../common/components/layout/Container";
import { ContainerLoader } from "../../common/components/loaders/ContainerLoader";
import Message from "../../common/components/ui/Message";
import { useFullMathScope } from "../../common/hooks/firebaseHooks";
import { locationSelectors } from "../location/locationSlice";
import { GoToQuestionnaireButton } from "../questionnaire/GoToQuestionnaireButton";
import { userAnswersSelectors } from "../questionnaire/userAnswersSlice";
import Actions from "./Actions";
import CategoryBasedCallToAction from "./CategoryBasedCallToAction";
import FootprintComparison from "./FootprintComparison";
import GoToRecommendationBar from "./GoToRecommendationBar";
import ProfileCard from "./ProfileCard";
import {
  MathScopes,
  OrdinaryQuestionCategory,
} from "@pslifestyle/common/src/types/genericTypes";
import { CategorizedFootprint } from "@pslifestyle/common/src/types/questionnaireTypes";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

type RankedCategory = {
  label: OrdinaryQuestionCategory;
  footprint: number;
  countryAverage: number;
  relativeFootprint: number;
};

const determineHighestLowestCategory = (
  totalFootprint: number,
  categorizedFootprint: CategorizedFootprint,
  fullMathScope: MathScopes,
) => {
  let lowestCategory: RankedCategory = {
    label: "housing", // any is okay at this point
    footprint: 0,
    relativeFootprint: 99999999,
    countryAverage: 0,
  };
  let highestCategory: RankedCategory = {
    label: "housing", // any is okay at this point
    footprint: 0,
    relativeFootprint: 0,
    countryAverage: 0,
  };

  Object.entries(categorizedFootprint).forEach(([label, footprint]) => {
    const constKey = `RELATIVE_${label.toUpperCase()}_SHARE_OF_AVG_FOOTPRINT`;
    const countryAverage: number = fullMathScope[constKey] || 0.25;
    const userRelativeFootprint = footprint / totalFootprint;
    const relativeFootprint = userRelativeFootprint / countryAverage;

    if (relativeFootprint > highestCategory.relativeFootprint) {
      highestCategory = {
        label: label as OrdinaryQuestionCategory,
        relativeFootprint,
        countryAverage,
        footprint,
      };
    }
    if (relativeFootprint < lowestCategory.relativeFootprint) {
      lowestCategory = {
        label: label as OrdinaryQuestionCategory,
        relativeFootprint,
        countryAverage,
        footprint,
      };
    }
  });

  return { lowestCategory, highestCategory };
};

const ResultsPage = (): JSX.Element | null => {
  const navigate = useNavigate();
  const country = useSelector(locationSelectors.country);
  const hasAnswers = useSelector(userAnswersSelectors.hasAnswers);
  const categorizedFootprint = useSelector(
    userAnswersSelectors.categorizedFootprint,
  );
  const ordinaryAnswers = useSelector(userAnswersSelectors.ordinaryAnswers);
  const totalFootprint = useSelector(userAnswersSelectors.totalFootprint);

  const { error, loading, fullMathScope } = useFullMathScope();
  const { t } = useTranslation("results");

  useEffect(() => {
    if (!country) navigate("/selections");
    if (!hasAnswers || !fullMathScope) navigate("/test");
  }, [country, hasAnswers, navigate, fullMathScope]);

  const { lowestCategory, highestCategory } = fullMathScope
    ? determineHighestLowestCategory(
        totalFootprint,
        categorizedFootprint,
        fullMathScope,
      )
    : { lowestCategory: null, highestCategory: null };

  return (
    <ContainerLoader loading={loading}>
      {(!hasAnswers || !country || error || !fullMathScope) && (
        <FullWidthContainer>
          <Message text={t("noResultsYet", { ns: "results" })}>
            <GoToQuestionnaireButton />
          </Message>
        </FullWidthContainer>
      )}
      {hasAnswers &&
        country &&
        fullMathScope &&
        highestCategory &&
        lowestCategory && (
          <>
            <ProfileCard
              totalFootprint={Math.round(totalFootprint)}
              targetFootprint={Math.round(
                fullMathScope.ONE_POINT_FIVE_DEGREE_TARGET,
              )}
              categorizedFootprint={categorizedFootprint}
              countryCode={country.code}
              lowestCategoryLabel={lowestCategory.label}
              highestCategoryLabel={highestCategory.label}
            />
            <FootprintComparison
              ordinaryAnswers={ordinaryAnswers}
              categorizedFootprint={categorizedFootprint}
              countryCode={country.code}
              totalFootprint={totalFootprint}
              targetFootprint={Math.round(
                fullMathScope.ONE_POINT_FIVE_DEGREE_TARGET,
              )}
              averageFootprintPerCountry={Object.entries(fullMathScope).reduce(
                (aggregator: Record<string, number>, [key, value]) => {
                  if (key.includes("AVG_FOOTPRINT_")) {
                    return { ...aggregator, [key.slice(-2)]: value };
                  }

                  return aggregator;
                },
                {},
              )}
            />
            <CategoryBasedCallToAction
              highestCategoryLabel={highestCategory.label}
            />
            <Actions
              totalFootprint={totalFootprint}
              categorizedFootprint={categorizedFootprint}
              fullMathScope={fullMathScope}
            />
            <GoToRecommendationBar />
          </>
        )}
    </ContainerLoader>
  );
};

export default ResultsPage;
